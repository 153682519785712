import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { HTTPStatus } from './http.status';
import { catchError, finalize, map } from 'rxjs/operators';
import { AlertService } from '../alert.service';
import { StatusCodes } from '../../domain/status-codes';

@Injectable()
export class Interceptor implements HttpInterceptor {

  isRefreshingToken = false;

  readonly INVALID_TOKEN = 'invalid_token';
  readonly USER_NOT_FOUND = 'user not found';

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private status: HTTPStatus,
    private alertService: AlertService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.handleIfRefreshingToken(req, next);

    const authReq = this.cloneWithHeaders(req);

    return next.handle(authReq).pipe(
      map(event => {
        this.status.setHttpStatus(true);
        return event;
      }),
      catchError(error => {

   /*     if (error.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          // console.log('An client-side or network error occurred:', error.error.message);
          this.status.setHttpStatus(false);
          return throwError(error);
        } else {*/
          this.handleServerErrorCodes(authReq, next, error);
          this.status.setHttpStatus(false);
          return throwError(error);
       // }
      }),
      finalize(() => {
        this.status.setHttpStatus(false);
      })
    );
  }


  handleIfRefreshingToken(req, next) {
    if (this.isRefreshingToken) {
      let waitTime = 300;
      if (req.url !== this.authenticationService.OAUTH_URL) {
        waitTime = 0;
      }
      setTimeout(() => {
        return next.handle(this.cloneWithHeaders(req)).pipe(catchError(error => {
            return throwError(error);
          }));
      }, waitTime);
    }
  }

  isErrorWhilePostingCredentials(authReq, error) {
    return error.url.indexOf(this.authenticationService.OAUTH_URL) > -1 && authReq.body.has('username');
  }

  handleServerErrorCodes(authReq, next, error) {
    if (this.isErrorWhilePostingCredentials(authReq, error)) {
      return throwError(error);
    } else if (error.status === StatusCodes.UNAUTHORIZED
      && error.error && error.error.error
      && error.error.error === this.INVALID_TOKEN) {
      // try with refresh token
      this.renewAccessToken(authReq, next);
    } else if (
      (error.status === StatusCodes.NOT_FOUND && error.url && error.url.indexOf(this.authenticationService.LOGIN_URL) > -1) ||
      (error.status === StatusCodes.INTERNAL_SERVER_ERROR && error.error.message && (<string>error.error.message).toLowerCase().indexOf(this.USER_NOT_FOUND) > -1) ||
      (error.status === StatusCodes.BAD_GATEWAY)
    ) {
      this.authenticationService.logout();
      this.router.navigate([this.authenticationService.LOGIN_URL]);
      location.reload();
      return throwError(error);
    } else {
      if (error.error instanceof ErrorEvent) {
        // client-side error
        this.alertService.error('ClientError');
      } else {
        // server-side error
        this.alertService.error('ServerError');
      }
    }
  }

  cloneWithHeaders(req) {
    if (sessionStorage.getItem('access_token') && req.url !== this.authenticationService.OAUTH_URL) {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
      });
      return req.clone({ headers });
    } else {
      return req;
    }
  }

  renewAccessToken(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      const token_object = sessionStorage.getItem('token_object');
      const storedTokenObject: any = JSON.parse(token_object);
      if (storedTokenObject && storedTokenObject['refresh_token']) {

        this.authenticationService.refreshToken(storedTokenObject['refresh_token'])
          .subscribe(data => {
            this.isRefreshingToken = false;
            return next.handle(this.cloneWithHeaders(req));
          }, err => {
            this.authenticationService.logout();
          });
      }
    }
  }

}
