import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModuleConstants } from '../domain/module-constants';

@Injectable()
export class LanguageService {

  constructor(private translateService: TranslateService) { }

  switchToLanguage(language: string) {
    let langCode;
    if (language.toLocaleLowerCase() === 'dutch') {
      langCode = 'nl';
    } else if (language.toLocaleLowerCase() === 'english') {
      langCode = 'en';
    } else {
      langCode = language;
    }

    this.switchToi14N(langCode);
  }

  switchToi14N(langCode: string) {
    sessionStorage.setItem('i18n', langCode);
    this.translateService.use(langCode);
  }

  setUserLocale() {
    const langCode = sessionStorage.getItem('i18n');
    if (langCode) {
      this.switchToi14N(langCode);
    } else {
      this.switchToBrowserLocale();
    }
  }

  switchToBrowserLocale() {
    const browserLocale = (navigator.language).split('-')[0];
    if (ModuleConstants.SUPPORTED_LOCALE.indexOf(browserLocale) >= 0) {
      this.switchToi14N(browserLocale);
    } else {
      this.switchToi14N('en');
    }
  }

}
