import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { LocalizedDatePipe } from './localized-date.pipe';
@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [LocalizedDatePipe],
    providers: [LocalizedDatePipe],
    exports:   [LocalizedDatePipe]
})
export class SharedPipesModule {

    static forRoot() {
      return {
          ngModule: SharedPipesModule,
          providers: [],
      };
   }
}
