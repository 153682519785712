import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Injectable()
export class SharedService {

  public reportRow = new Subject<any>();
  public eventDetails = new Subject<any>();
  private siteId: BehaviorSubject<any> = new BehaviorSubject(null);
  private loginSubject = new Subject<any>();
  private sparkData = new Subject<any>();

  public constructor() {}

  selectedReportRow(row) {
    this.reportRow.next(row);
  }

  getSelectedReportRow(): Observable<any> {
    return this.reportRow;
  }

  setEventDetailsData(eventData) {
    this.eventDetails.next(eventData);
  }

  getEventDetailsData(): Observable<any> {
    return this.eventDetails;
  }


  selectedSite(siteId) {
    this.siteId.next(siteId);
  }

  getSelectedSiteId(): Observable<any> {
    return this.siteId.asObservable();
  }

  error(message: string) {
    this.loginSubject.next(message);
  }

  getMessage(): Observable<any> {
    return this.loginSubject.asObservable();
  }

  setSparkData(sparkData) {
    this.sparkData.next(sparkData);
  }

  getsetSparkData(): Observable<any> {
    return this.sparkData.asObservable();
  }



}
