import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './app-routing-guard';
import { AuthenticationService } from './services/authentication.service';
const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/layout.module').then(m => m.LayoutModule),
    canActivate: [AuthGuard]
  },
  { path: 'login', loadChildren: './login/login.module#LoginModule' },
  { path: 'reset-password', loadChildren: './reset-password/reset-password.module#ResetPasswordModule' },
  { path: 'forgot-password', loadChildren: './forgot-password/forgot-password.module#ForgotPasswordModule' },
  { path: 'twoFactorAuthentication', loadChildren: './two-factor-authentication/two-factor-authentication.module#TwoFactorAuthenticationModule' },
  { path: 'not-found', loadChildren: './not-found/not-found.module#NotFoundModule' },
  { path: '**', loadChildren: './not-found/not-found.module#NotFoundModule' }, //redirectTo: 'not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    AuthGuard,
    AuthenticationService
  ]
})
export class AppRoutingModule { }
