import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot, CanLoad, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
    constructor(
        private router: Router,
        private authService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.authService.isLoggedIn()) {
            this.router.navigate(['login'], { queryParams: { returnUrl: state.url }} );
            return false;
        }

        const expectedPrivilege = route.data.privileges;

        if (expectedPrivilege && !expectedPrivilege.some(p => this.authService.hasPrivilege(p))) {
            this.router.navigate(['error', 'not-found']);
            return false;
        }

        return true;
    }

    canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.authService.isLoggedIn()) {
            this.router.navigate(['login']);
            return false;
        }

        const expectedPrivilege = route.data.privileges;
        if (expectedPrivilege && !expectedPrivilege.some(p => this.authService.hasPrivilege(p))) {
            this.router.navigate(['error', 'not-found']);
            return false;
        }

        return true;
    }
}
