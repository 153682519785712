import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';

@Injectable({
  providedIn: 'root'
})
export class HighchartsConfigService {
  constructor() {
    Highcharts.setOptions({
      chart: {
        style: {
          fontFamily: 'var(--main-font)'
        }
      }
    });
  }
}