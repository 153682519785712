import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { HTTPStatus } from './services/interceptor/http.status';
import { AuthenticationService } from './services/authentication.service';
import { LanguageService } from './services/language.service';
import { RoutingState } from './services/routing-state.service';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { SharedService } from './services/shared.service';
import { Interceptor } from './services/interceptor/interceptor';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

registerLocaleData(localeNl, 'nl');

function localeFactory(): string {
  const userLocale = navigator.language;
  return userLocale || 'nl-NL';
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SweetAlert2Module.forRoot({}),
    SharedPipesModule.forRoot(),
  ],
  providers: [
    SharedService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useFactory: localeFactory,
    },
    HTTPStatus,
    AuthenticationService,
    LanguageService,
    RoutingState
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
